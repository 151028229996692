import React from 'react'
import globals from '@pods-finance/globals'

export default {
  title: (
    <>
      What are your <b>options</b>?
    </>
  ),
  description: (
    <>
      Here are some examples of how to calibrate your portfolio's risk using the
      Pods Options Protocol.
    </>
  ),
  more: 'Load more strategies',
  list: [
    {
      label: 'Strategy',
      title: 'Protect your WBTC',
      description: (
        <>
          Suppose BTC (or WBTC) is an asset in your portfolio. In that case, you
          understand what <b>volatility</b> is, and its downside risk may
          concern you. Buying a put option lets you limit your exposure to how
          far the price could fall until a specific date. It works as purchasing{' '}
          <b>insurance</b> for your BTC price.
        </>
      ),
      call: 'Hedge your risk',
      to: globals.host.platform.auto('/buy?t=buy&u=WBTC'),
      isDark: true
    },
    {
      label: 'Strategy',
      title: 'Bullish on ETH',
      description: (
        <>
          Leverage your beliefs by selling put options on ETH and making sure
          that you buy it if the price hits a certain level. Immediately earn a
          premium and accrue interest from yield-bearing tokens held as
          collateral. If the price doesn't hit that level at expiration, you get
          the collateral back.
        </>
      ),
      call: 'Invest your assets',
      to: globals.host.platform.auto('/sell?t=sell&u=ETH'),
      isDark: false
    },
    {
      label: 'Strategy',
      title: 'Earn with Smart Collateral',
      description: (
        <>
          You are thinking of selling put options in order to earn premium.
          Generate additional income by using <b>smart assets</b> such as Aave's
          aTokens. At the end of the exercise window, you will be able to
          withdraw your idle funds (not-exercised) and the interest yielded
          throughout the lock period.
        </>
      ),
      call: 'Invest your assets',
      to: undefined,
      isDark: false
    },
    {
      label: 'Strategy',
      title: 'Bearish on ETH',
      description: (
        <>
          You are bearish on ETH (think the price will go down) and want to
          reduce your exposure to the asset. You could buy put options with a
          strike price (e.g. $500) that is higher than the expected spot price
          (e.g. $300). This means that if at expiry, the price of ETH falls
          below $300, you have the right to sell it for the strike of $500.
        </>
      ),
      call: 'Hedge your risk',
      to: globals.host.platform.auto('/buy?t=buy&u=ETH'),
      isDark: true
    },
    {
      label: 'Strategy',
      title: 'The "Minter"',
      description: (
        <>
          In order to earn additional income with Pods you can become a
          Liquidity Provider. By taking on "nonlinear exposure", you will mint
          and provide option tokens and strike assets to our custom AMM. While
          your funds will be continuously traded, you earn a premium over time,
          AMM accumulated fees and smart collateral interest.
        </>
      ),
      call: 'Mint and Earn',
      to: globals.host.platform.auto('/pool'),
      isDark: true
    },
    {
      label: 'Strategy',
      title: 'Seeking the Premium',
      description: (
        <>
          You have stable assets in your wallet and you're looking to make an
          investment into your bullish beliefs. You think the underlying asset
          price won't go below the strike price by the maturity. Invest those
          tokens by selling put options. Earn premium for taking on the risk and
          claim some interest for smart assets such as aDAI or aUSDC.
        </>
      ),
      call: 'Invest your assets',
      to: globals.host.platform.auto(
        '/sell?c=ADAI%2CAUSDC%2CDAI%2C USDC&t=sell'
      ),
      isDark: false
    }
  ]
}
