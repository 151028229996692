import React from 'react'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'
import AssetLegoLight from '../../assets/lego/light.png'
import AssetLegoPrimary from '../../assets/lego/primary.png'
import AssetLegoGroupPrimaryDark from '../../assets/lego/groupPrimaryDark.png'
import AssetLegoGroupLightDark from '../../assets/lego/groupLightDark.png'

export default {
  title: 'Take risks and earn with advanced flows',
  description:
    'As an option seller you can engage in various activities to earn additional income with options. Learn more about how you can earn by interacting with the Pods Options Protocol.',
  list: [
    {
      piece: AssetLegoGroupPrimaryDark,
      title: 'Smart Collateral',
      description: (
        <>
          Earn interest with{' '}
          <ExternalLink to={globals.links.aave.about.link}>Aave</ExternalLink>{' '}
          tokens while providing collateral or liquidity for your options.
        </>
      ),
      action: 'Read more',
      hash: '#smart'
    },
    {
      piece: AssetLegoLight,
      title: 'Sell Put Options',
      description: 'Sell put options and earn the premium. Instant exposure.',
      action: 'Open app',
      to: globals.host.platform.auto()
    },
    {
      piece: AssetLegoPrimary,
      title: 'Sell Call Options',
      description: 'Sell call options and earn the premium.',
      action: 'Open app',
      to: globals.host.platform.auto()
    },
    {
      piece: AssetLegoGroupLightDark,
      title: 'Provide Liquidity',
      description:
        'Provide liquidity with option tokens and/or stablecoins to the AMM. Earn fees from trading activity in the pools.',
      action: 'Read more',
      hash: '#liquidity'
    }
  ]
}
